import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./press-kit.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "@gatsbyjs/reach-router"
import MetaTags from "../components/MetaTags"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import { TwitterTweetEmbed } from "react-twitter-embed"
import SyntaxHighlighter from 'react-syntax-highlighter';

import { faClipboard } from "@fortawesome/free-solid-svg-icons"

var beautify_html = require('js-beautify').html;

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { atelierCaveDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { toast, ToastContainer } from "react-toastify"

// markup
const TermsOfService = () => {
    const [modeSelected, setModeSelected] = React.useState(0);

    const pageRef: any = useRef(null)
    const [code, setCode] = React.useState('');

    React.useEffect(() => {
        let data = pageRef.current.innerHTML


        // Replace Tweet embed 
        const regex = /<div.*class\s*=\s*["'].*tweet-embed.*["']\s*>(.*)<\/div>/;
        const subst = `
        <blockquote class="twitter-tweet">
            <p lang="id" dir="ltr">Barangkali kamu melewatkan 
                <a href="https://twitter.com/KtpAnabul?ref_src=twsrc%5Etfw">@KtpAnabul</a>! 🎉
                <br><br>Aplikasi yang memungkinkan untuk membuat KTP Kucing/Anjing dalam sekejap! ✨
                <br><br>Tersedia berbagai pilihan tema dan dilengkapi teknologi AI untuk menghapus
                 latar belakang secara otomatis. Yuk dicoba! 🐈🦮<br><br>→
                 
                 <a href="https://t.co/9Lfd9qO7UY">https://t.co/9Lfd9qO7UY</a> 👈
                 <a href="https://t.co/MZraADLG37">pic.twitter.com/MZraADLG37</a>
            </p>
            &mdash; Khakim Hudaya (@huedaya)
            <a href="https://twitter.com/huedaya/status/1614213924232253442?ref_src=twsrc%5Etfw">January 14, 2023</a>
        </blockquote>
        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`;
        data = data.replace(regex, subst);

        data = beautify_html(data)
        console.log(data)
        setCode(data)
    })


    return (
        <>
            <MetaTags
                metaDescription="Press Kit"
                metaTitle="Press Kit"
                metaPath="/press-kit"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>

                        <h1>Press Kit</h1>


                        <p>Halo teman-teman media, berikut merupakan artikel berita yang bisa disalin secara bebas, terdapat pula pilihan{' '}
                            <a onClick={() => { setModeSelected(1) }} >Kode HTML</a>. Jika terdapat pertanyaan, mohon menghubungi melalui kontak yang
                            ada pada halaman <Link to="/about">Tentang Aplikasi</Link>. Terima Kasih.
                        </p>
                        <br />
                        <br />

                        <div className={css.codeSwitcherContainer}>
                            <div className={css.codeSwitcher}>
                                <input
                                    id="monthly"
                                    className="monthly"
                                    type="radio"
                                    name="switch"
                                    checked={(modeSelected == 0 ? true : false)}
                                    onClick={() => setModeSelected(0)}
                                    readOnly={true}
                                />
                                <label htmlFor="monthly">Teks</label>
                                <input
                                    id="yearly" className="yearly" type="radio" name="switch"
                                    checked={(modeSelected == 1 ? true : false)}
                                    onClick={() => setModeSelected(1)}
                                    readOnly={true}
                                />
                                <label htmlFor="yearly">Kode HTML</label>
                                <span className="highlighter" data-mode-selected={modeSelected}></span>
                            </div>
                        </div>

                        <div className={css.pagePreview} ref={pageRef} data-is-visible={(modeSelected == 0 ? true : false)}>
                            <h1>KTP Anabul, Aplikasi Ajaib yang bisa Membantu Menemukan Kucing Hilang</h1>

                            <img
                                src="https://bucket-prod.ktpanabul.com/assets/press-kit/press-kit_cover.jpg"
                                alt="Membuat KTP untuk Anabul tidak pernah semudah ini. (Sumber Gambar: KTP Anabul/Chika Maemunah)"
                            />
                            <span id="caption">Membuat KTP untuk Anabul tidak pernah semudah ini. (Sumber Gambar: KTP Anabul/Chika Maemunah)</span>
                            <p>
                                Ternyata bukan hanya manusia yang bisa punya kartu tanda penduduk (KTP), anabul juga bisa lho.
                                Anabul adalah singkatan dari anak bulu atau istilah lain untuk Kucing/Anjing.
                                Pembuatan KTP anabul kini tidak sesulit sebelumnya karena programmer bernama
                                Khakim Hudaya membuat aplikasi yang memudahkan proses ini.
                                Tidak sekadar jadi penanda identitas,
                                tetapi aplikasi ini juga bisa membantu ketika anabul hilang.
                            </p>
                            <p>
                                Aplikasi KTP Anabul, begitulah ia menamainya. Semenjak diluncurkan pada Desember 2022 lalu,
                                aplikasi ini berhasil menarik perhatian lebih dari ribuan pemilik anabul di Indonesia.
                                Dalam situs tersebut, pengguna tidak perlu repot mendesain KTP,
                                tinggal klik saja hal-hal yang sesuai dengan kondisi anabul.
                            </p>
                            <p>
                                Layaknya kartu identitas milik manusia, pada KTP anabul terdapat area domisili di bagian atas.
                                Lalu, nomor induk kependudukan, tempat dan tanggal lahir, jenis bulu, warna mata,
                                status perkawinan, hobi, makanan favorit,
                                hingga nama sahabat manusia atau pemilik anabul tersebut.
                                Terdapat juga berbagai tema yang bisa dipilih yang semua dilakukan otomatis oleh
                                algoritma dalam hitungan detik, wah canggih ya!
                            </p>

                            <div className={'tweet-embed'}>
                                <TwitterTweetEmbed
                                    tweetId={'1614213924232253442'}
                                />
                            </div>
                            <h2>Aplikasi yang membantu menemukan anabul hilang</h2>

                            <p>
                                Selain membuat KTP, website dilengkapi fitur yang bisa membantu pemilik mencari anabulnya yang hilang.
                                Hal itu dapat dilakukan karena setiap anabul yang terdaftar di KTP Anabul akan otomatis
                                memiliki halaman profil seperti <a href="https://ktpanabul.com/@bonbon" target="_blank">ktpanabul.com/@bonbon</a>.
                            </p>
                            <p>
                                Halaman itu memuat foto dan berbagai informasi dari anabul yang bisa diakses publik.
                                Sebagai langkah awal, data tersebut dapat ditambahkan di kalung anabul dalam bentuk
                                kode QR atau tautan/link. Dengan demikian, ketika anabul tak kunjung pulang dan ditemukan orang,
                                keterangan kepemilikan anabul bisa diakses melalui kode QR tersebut.
                            </p>
                            <p>
                                Penemu anabul hilang bisa menghubungi pemilik melalui kontak dan informasi yang tertera di
                                halaman profil khusus tadi. Situs juga memastikan privasi nomor telepon tetap terjaga.
                                Sebab, halaman profil anabul tersembunyi dari mesin pencari.
                                Jadi, data sensitif tidak tersebar ke internet.
                            </p>

                            <img
                                src="https://bucket-prod.ktpanabul.com/assets/press-kit/press-kit_1.jpg"
                                alt="KTP Anabul juga menyediakan pembuatan dan pengiriman kalung dengan kode QR yang bisa membantu menemukan anabul yang hilang. (Sumber Gambar: Khakim Hudaya/KTP Anabul)"
                            />
                            <span id="caption">KTP Anabul juga menyediakan pembuatan dan pengiriman kalung dengan kode QR yang bisa membantu menemukan anabul yang hilang. (Sumber Gambar: Khakim Hudaya/KTP Anabul)</span>

                            <h2>Cara mendaftarkan Anabul </h2>

                            <p>
                                Khakim menambahkan "<i>Aplikasi KTP Anabul didesain agar sangat mudah digunakan,
                                    tinggal login pakai alamat surel (<i>e-mail</i>),
                                    tanpa perlu meng-install atau mengingat kata sandi</i>."
                            </p>
                            <p>
                                Untuk menggunakan aplikasi ini pengguna cukup menyiapkan alamat surat elektronik
                                aktif dan foto dari anabul.
                                Kemudian, pengguna bisa mengakses situs <a href="https://ktpanabul.com" target="_blank">ktpanabul.com</a> dan melakukan <i>login</i> dengan surel tersebut.
                                Selebihnya pengisian informasi anabul akan dipandu dalam aplikasi tersebut.
                            </p>
                            <p>
                                Pendaftaran anabul tidak dipungut biaya, sementara untuk mengunduh KTP dikenakan biaya 2 ribu rupiah
                                dan pengguna bisa <i>upgrade</i> ke jenis akun Pro untuk mengakses semua pilihan tema dengan
                                biaya yang sangat murah.
                            </p>

                            <img
                                src="https://bucket-prod.ktpanabul.com/assets/press-kit/press-kit_2.jpg"
                                alt="Salah satu anabul dan KTP yang dibuat di website KTP Anabul. (Sumber Gambar: Chika Maemunah)"
                            />
                            <span id="caption">Salah satu anabul dan KTP yang dibuat di website KTP Anabul. (Sumber Gambar: Chika Maemunah)</span>

                            <h2>Cerita unik dibalik terciptanya KTP Anabul</h2>
                            <p>
                                Ide membuat KTP untuk anabul tersebut terlintas sejak 3 tahun lalu. Di saat dia bersama
                                teman-teman kuliahnya berebut memberi nama kucing yang ditemui di area kampus.
                                Hingga akhirnya sebagai jalan tengah, dibuatlah KTP untuk para bayi kucing tersebut.
                                Dengan foto asal jepret dan didesain manual, maka jadilah KTP buat mereka.
                                KTP Kucing tersebut juga sempat ramai disosial media, hingga setahun berlalu dibuatlah
                                aplikasi untuk membuatnya bisa digunakan semua orang dan berjalan secara otomatis.
                            </p>
                            <p>
                                Selain itu, pengalaman melihat orang di sekelilingnya kehilangan hewan kesayangan membuatnya
                                tergerak untuk bisa menghasilkan sesuatu yang membantu proses pencarian. &quot;<i>Saat pindah ke Bali,
                                    aku menyadari manusia bisa sedekat itu dengan anjing atau kucing. Namun, yang bikin sedih,
                                    kadang di media sosial lokal banyak pengumuman anjing dan kucing hilang,
                                    dan aku rasa pembuatan situs yang tersentral bisa membantu menyelesaikan masalah ini.</i>&quot; tuturnya.
                            </p>
                            <p>
                                Ke depan, dia berharap bakal lebih banyak lagi pemilik anabul yang terkoneksi.
                                Situs pun akan terus diperbarui dengan fitur-fitur menarik lainnya.
                                Sebagaimana informasi lokasi vet terdekat, grup diskusi,
                                hingga halaman untuk membuat flyer anabul hilang yang mudah digunakan.
                            </p>


                            <img
                                src="https://bucket-prod.ktpanabul.com/assets/press-kit/press-kit_3.jpg"
                                alt="Ide membuat website KTP anabul berawal dari masa kuliah. Ketika Khakim Hudaya dan teman-teman berebut memberi nama anak kucing yang beredar di kampus. (Sumber Gambar: Khakim Hudaya)"
                            />
                            <span id="caption">Ide membuat website KTP anabul berawal dari masa kuliah. Ketika Khakim Hudaya dan teman-teman berebut memberi nama anak kucing yang beredar di kampus. (Sumber Gambar: Khakim Hudaya)</span>
                        </div>

                        <div className={css.codePreview} data-is-visible={(modeSelected == 1 ? true : false)}>

                            <CopyToClipboard text={code}
                            >
                                <div className={css.copyToClipboard}
                                    onClick={() => {
                                        toast.success('Kode HTML Berhasil disalin!', {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        })
                                    }}>
                                    <FontAwesomeIcon icon={faClipboard} /> Salin Ke Clipboard
                                </div>
                            </CopyToClipboard>
                            <SyntaxHighlighter
                                language="xml"
                                style={atelierCaveDark}
                                lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                wrapLines={true}
                                showLineNumbers={true}
                            >
                                {code}
                            </SyntaxHighlighter>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* Same as */}
            <ToastContainer />
            <Footer />
        </>
    )
}

export default TermsOfService
